import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonComponent } from '../../../button/button.component';
import { AppService } from '../../../../services/app.service';
import { AsyncPipe } from '@angular/common';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-book-shelf-bean-prompt',
  templateUrl: './book-shelf-bean-prompt.component.html',
  styleUrls: ['./book-shelf-bean-prompt.component.scss'],
  standalone: true,
  imports: [ButtonComponent, AsyncPipe],
})
export class BookShelfBeanPromptComponent {
  @Input({ required: true }) beanLabel!: string;
  @Input({ required: true }) buttonLabel!: string;
  @Output() buttonClicked: EventEmitter<number> = new EventEmitter<number>();

  constructor(public appService: AppService) {}
}
