<div class="bean-prompt">
  <div>
    @if (appService.isMobile$ | async) {
      <div class="mobile-bean-bubble-container">
        <div class="bean-bubble">{{ beanLabel }}</div>
        <div class="bean-container">
          <img src="assets/imgs/buddy-happy.svg" alt="" />
        </div>
      </div>
      <app-button [expand]="'block'" [size]="'small'" [label]="buttonLabel" (clicked)="buttonClicked.emit()"></app-button>
    } @else {
      <div class="desktop-bean-bubble-container">
        <div class="bean-bubble">
          <div class="label">{{ beanLabel }}</div>
          <app-button [expand]="'default'" [label]="buttonLabel" (clicked)="buttonClicked.emit()"></app-button>
        </div>
        <div class="bean-container">
          <img src="assets/imgs/buddy-happy.svg" alt="" />
        </div>
      </div>
    }
  </div>
</div>
